import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as CoffeeIcon } from "../../assets/Coffee.svg";
import { ReactComponent as HeadPhoneIcon } from "../../assets/HeadPhone.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/LinkedIn.svg";
import { ReactComponent as MediumIcon } from "../../assets/Medium.svg";
import { ReactComponent as MailIcon } from "../../assets/Mail.svg";
import { ReactComponent as SignatureIcon } from "../../assets/Signature.svg";
// import { ReactComponent as Goodreads } from "../../assets/book.svg";
import { ReactComponent as PodcastIcon } from "../../assets/podcast.svg";
import { ReactComponent as PhoneIcon } from "../../assets/phone-call.svg";
import { ReactComponent as BookIcon } from "../../assets/book.svg";

//

export const AboutSectionNoWrap = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "center",

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    flexWrap: "nowrap",
  },
}));

export const AboutSectionLeft = styled(Box)(({ theme }) => ({
  paddingRight: theme.spacing(1),
  width: "100%",
  margin: "0 auto 2.5rem",
  textAlign: "center",
  maxWidth: "380px",

  [theme.breakpoints.up("md")]: {
    maxWidth: "auto",
    flex: "1 1 25%",
  },
}));

export const AboutCard = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.mainBg.secondary
      : theme.palette.secondary.lighter,

  position: "relative",
  borderRadius: "0.625rem",
  border: `1px solid ${theme.palette.primary.darker}`,
  margin: "0.5em",
  padding: "1.25rem",
  transition: "all 0.5s",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  display: "inline-block",
  width: "250px",
  height: "250px",
  marginBottom: theme.spacing(1),
  borderRadius: theme.spacing(6.25, 0, 6.25, 6.25),
  transition: "all 0.5s",

  "&:hover": {
    borderRadius: theme.spacing(6.25, 6.25, 6.25, 0),
  },

  [theme.breakpoints.up("sm")]: {
    width: "300px",
    height: "300px",
  },
}));

export const AboutImage = styled(Box)({
  width: "100%",
  height: "130%",
  objectFit: "cover",
});

export const PersonalInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),
}));

export const Name = styled(Typography)(({ theme }) => ({
  color:
    theme.palette.mode === "dark"
      ? theme.palette.primary.light
      : theme.palette.primary.dark,
  textAlign: "center",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.dark,
  },
}));

export const AboutSectionRight = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  width: "100%",

  [theme.breakpoints.up("md")]: {
    flex: "1 1 75%",
  },
}));

export const SocialLinks = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(2),
}));

export const TitleWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0),
  color:
    theme.palette.mode === "dark"
      ? theme.palette.primary.light
      : theme.palette.common.black,
}));

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  boxShadow: "none",
  padding: "8px",
  display: "flex",
  alignItems: "center",
  borderRadius: "8px",
  lineHight: 0,
  backgroundColor: theme.palette.secondary.light,
  transition: "all 0.5s ease-in-out",

  "&:hover": {
    backgroundColor: theme.palette.secondary.light,
    boxShadow: "none",
    transform: "translateY(-5px)",
  },
}));

export const LinkedIn = styled(LinkedInIcon)(({ theme }) => ({
  cursor: "pointer",
  transition: "all 0.5s ease-in-out",
  path: {
    fill: theme.palette.primary.dark,
  },
}));

export const Medium = styled(MediumIcon)(({ theme }) => ({
  cursor: "pointer",
  transition: "all 0.5s ease-in-out",
  path: {
    fill: theme.palette.primary.dark,
  },
}));

export const Mail = styled(MailIcon)(({ theme }) => ({
  cursor: "pointer",
  transition: "all 0.5s ease-in-out",
  path: {
    fill: theme.palette.primary.dark,
  },
}));

export const Book = styled(BookIcon)(({ theme }) => ({
  cursor: "pointer",
  transition: "all 0.5s ease-in-out",
  width: "24px", // Adjust the size as needed
  height: "24px",
  path: {
    fill: theme.palette.primary.dark,
  },
}));

export const Coffee = styled(CoffeeIcon)(({ theme }) => ({
  cursor: "pointer",
  transition: "all 0.5s ease-in-out",
  path: {
    fill: theme.palette.primary.dark,
  },
}));

export const Podcast = styled(PodcastIcon)(({ theme }) => ({
  cursor: "pointer",
  transition: "all 0.5s ease-in-out",
  width: "24px", // Adjust the size as needed
  height: "24px",
  path: {
    fill: theme.palette.primary.dark,
  },
}));

export const Signature = styled(SignatureIcon)(({ theme }) => ({
  // height: 250,
  width: 200,
  path: {
    fill:
      theme.palette.mode === "dark"
        ? theme.palette.primary.light
        : theme.palette.primary.dark,
  },
}));

//

export const TitleMain = styled(Typography)(({ theme }) => ({
  letterSpacing: ".0em",
  color:
    theme.palette.mode === "dark"
      ? theme.palette.primary.light
      : theme.palette.primary.dark,
}));

export const MailText = styled(Typography)(({ theme }) => ({
  ...theme.typography.boldSubhead,
  color:
    theme.palette.mode === "dark"
      ? theme.palette.primary.light
      : theme.palette.secondary.darker,
}));

//

export const ToolKitSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const ToolkitBoxWrapper = styled(Box)(({ theme }) => ({
  width: "auto",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const IconBoxWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  backgroundColor: theme.palette.grey.lighter,
  borderRadius: theme.spacing(1),
  transition: "all 0.5s ease-in-out",

  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

export const IconBox = styled(Box)(({ theme }) => ({
  height: theme.spacing(4),
  width: theme.spacing(4),
  [theme.breakpoints.up("sm")]: {
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
}));
