import oct2023 from "../assets/talks/oct2023.png";
import jun2023 from "../assets/talks/jun2023.jpeg";
import nov2023 from "../assets/talks/nov2023.png";
import nov2023_1 from "../assets/talks/nov_2023_1.png";
import sep2023 from "../assets/talks/sep2023.jpeg";
import sep2023_1 from "../assets/talks/sep2023_1.jpg";
import sep2023_2 from "../assets/talks/sep2023_2.jpeg";
import apr2023 from "../assets/talks/apr2023.jpeg";
import feb2024 from "../assets/talks/feb_2024.png";
import meetupfeb1 from "../assets/talks/meetup_feb_1.JPG";
import meetupfeb2 from "../assets/talks/meetup_feb_2.JPG";
import conveyux1 from "../assets/talks/conveyux1.JPG";
import conveyux2 from "../assets/talks/conveyux2.JPG";
import conveyux3 from "../assets/talks/conveyux3.JPG";
import conveyux4 from "../assets/talks/conveyux4.JPG";
import sphereit1 from "../assets/talks/sphereit_mar1.png";
import sphereit2 from "../assets/talks/sphereit_mar2.png";
import wids1 from "../assets/talks/wids_1.JPG";
import wids2 from "../assets/talks/wids_2.JPG";
import wids3 from "../assets/talks/wids_3.jpg";
import wids4 from "../assets/talks/wids_4.JPG";
import wids5 from "../assets/talks/wids_5.jpg";
import wids6 from "../assets/talks/wids_6.jpg";
import airising_1 from "../assets/talks/airising_1.jpg";
import airising_2 from "../assets/talks/airising_2.JPG";
import render1_jun2024 from "../assets/talks/Render_1_jun2024.jpg";
import render2_jun2024 from "../assets/talks/Render_2_jun2024.jpg";
import render3_jun2024 from "../assets/talks/Render_3_jun2024.jpg";
import gdgsurrey_1 from "../assets/talks/gdg_surrey_2024_1.png";
import gdgsurrey_2 from "../assets/talks/gdg_surrey_2024_2.png";
import gdgsurrey_3 from "../assets/talks/gdg_surrey_2024_3.png";
import gdgsurrey_4 from "../assets/talks/gdg_surrey_2024_4.png";
import finovate2024_1 from "../assets/talks/finovate2024_1.JPG";
import finovate2024_2 from "../assets/talks/finovate2024_2.png";
import finovate2024_3 from "../assets/talks/finovate2024_3.JPG";
import aicamp_2024_1 from "../assets/talks/aicamp_2024_1.jpg";
import aicamp_2024_2 from "../assets/talks/aicamp_2024_2.png";
import aicamp_2024_3 from "../assets/talks/aicamp_2024_3.png";
import aicamp_2024_4 from "../assets/talks/aicamp_2024_4.png";
import gdg_burnaby_2024_1 from "../assets/talks/gdg_burnaby_2024_1.png";
import gdg_burnaby_2024_2 from "../assets/talks/gdg_burnaby_2024_2.png";
import gdg_burnaby_2024_3 from "../assets/talks/gdg_burnaby_2024_3.png";
import datatalks_2024 from "../assets/talks/datatalks_2024.png";
import product_bulb_2024 from "../assets/talks/product_bulb_2024.png";
import ashaya_podcast_2024_1 from "../assets/talks/ashaya_podcast_2024_1.png";
import ashaya_podcast_2024_2 from "../assets/talks/ashaya_podcast_2024_2.png";

const speaker = [
  {
    id: "projectOne",
    title: "Apurva Misra - Machine Learning Engineer",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://youtu.be/suS45DIbIJ8?si=SiFBx5BvzbAO_7mj&t=10",
    images: [`${ashaya_podcast_2024_1}`, `${ashaya_podcast_2024_2}`],
    technology: ["Ashaya Podcast"],
  },
  {
    id: "projectOne",
    title: "Production Generative AI - Practical Use Cases and Challenges",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://youtu.be/WWFYH_XUux4?si=ycCUKzyz211TI23s&t=1",
    images: [`${product_bulb_2024}`],
    technology: ["The Product Bulb Podcast"],
  },
  {
    id: "projectOne",
    title:
      "Elevating RAG Systems: Practical Enhancements for Reliable Retrieval Augmented Generation",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://www.youtube.com/live/RexNgpgwmkk?si=RpRjgWdM2GeAqtmr&t=102",
    images: [`${datatalks_2024}`],
    technology: ["DataTalks.Club"],
  },
  {
    id: "projectOne",
    title:
      "Improving RAG One Step at a Time: Incremental Enhancements for Reliable Retrieval-Augmented Generation",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://gdg.community.dev/events/details/google-gdg-burnaby-presents-devfest-2024-by-gdg-burnaby/",
    images: [
      `${gdg_burnaby_2024_1}`,
      `${gdg_burnaby_2024_2}`,
      `${gdg_burnaby_2024_3}`,
    ],
    technology: ["DevFest 2024 by GDG Burnaby"],
  },
  {
    id: "projectOne",
    title:
      "Enhancing RAG Systems: Practical Strategies for Incremental Optimization",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://www.aicamp.ai/event/eventdetails/W2024112617",
    images: [
      `${aicamp_2024_1}`,
      `${aicamp_2024_2}`,
      `${aicamp_2024_3}`,
      `${aicamp_2024_4}`,
    ],
    technology: ["AICamp Vancouver 2024"],
  },
  {
    id: "projectOne",
    title:
      "Leveraging advanced AI to deliver accurate and adaptive customer service",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://globalfinance.connectmeinforma.com/218/agenda",
    images: [`${finovate2024_1}`, `${finovate2024_2}`, `${finovate2024_3}`],
    technology: ["Finovate Fall 2024"],
  },
  {
    id: "projectOne",
    title: "Powering complex solutions with Agentic systems",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://gdg.community.dev/events/details/google-gdg-surrey-presents-google-io-extended-2024-surrey/",
    images: [
      `${gdgsurrey_1}`,
      `${gdgsurrey_2}`,
      `${gdgsurrey_3}`,
      `${gdgsurrey_4}`,
    ],
    technology: ["Google I/O Extended GDG Surrey"],
  },
  {
    id: "projectOne",
    title: "Should you really be including GenAI in your products?",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://www.renderatl.com/#speakers",
    images: [`${render1_jun2024}`, `${render2_jun2024}`, `${render3_jun2024}`],
    technology: ["RENDER ATL"],
  },
  {
    id: "projectOne",
    title: "What you need to do to include GenAI in your products",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://www.airising.org/speakers",
    images: [`${airising_1}`, `${airising_2}`],
    technology: ["AI Rising"],
  },
  {
    id: "projectOne",
    title: "Human-AI Collaboration: MultiAgent Systems",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://www.eventbrite.ca/e/2024-vancouver-women-in-data-science-conference-tickets-781509525027",
    images: [
      `${wids1}`,
      `${wids2}`,
      `${wids3}`,
      `${wids4}`,
      `${wids5}`,
      `${wids6}`,
    ],
    technology: ["Vancouver Women in Data Science Conference"],
  },
  {
    id: "projectOne",
    title:
      "AI as your Career Ally: Harnessing technology for professional growth",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://sphere.it/event-series/inspiring-women-in-it/",
    images: [`${sphereit1}`, `${sphereit2}`],
    technology: ["spHEReit"],
  },
  {
    id: "projectOne",
    title: "How to Incorporate GenerativeAI into your Workflow",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://conveyux.com/speakers/apurva-misra/",
    images: [`${conveyux1}`, `${conveyux2}`, `${conveyux3}`, `${conveyux4}`],
    technology: ["CONVEYUX"],
  },
  {
    id: "projectOne",
    title: "AI for Everyone Workshop at Northeastern University",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://www.meetup.com/keeping-up-with-the-tech/events/298426396/",
    images: [`${meetupfeb1}`, `${meetupfeb2}`],
    technology: ["Keeping up with the Tech"],
  },
  {
    id: "projectOne",
    title: "Integrating Generative AI in Your Workflow",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://agiledevopseast.techwell.com/program/concurrent-sessions/integrating-generative-ai-your-workflow-agile-devops-east-2023",
    images: [`${nov2023_1}`, `${nov2023}`],
    technology: ["Agile+DevOps East"],
  },
  {
    id: "projectOne",
    title: "Current State of LLMs in Production",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://home.mlops.community/home/videos/current-state-of-llms-in-production",
    images: [`${oct2023}`],
    technology: ["LLMs in Production - Part III"],
  },
  {
    id: "projectOne",
    title: "How to Incorporate Generative AI into Your Product Design Workflow",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://uxstrat.com/usa/program",
    images: [`${sep2023_2}`, `${sep2023_1}`, `${sep2023}`],
    technology: ["Workshop", "UX STRAT"],
  },
  {
    id: "projectOne",
    title: "Expert Panel discussing Generative AI and ChatGPT",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://www.dropbox.com/s/0hdszxhu2o4k9db/Design%20Thinktank_Generative%20AI%20and%20ChatGPT.mp4?dl=0",
    images: [`${jun2023}`],
    technology: ["Panel Discussion", "UX STRAT"],
  },
  {
    id: "projectOne",
    title: "Is MLOps basically Data Engineering rebranded?",
    description: "",
    intellectualPropertyOwner: "",
    companyName: "",
    role: "",
    link: "https://semla.quebec/mlops/",
    images: [`${apr2023}`],
    technology: ["MLOps Day at SEMLA Research Center"],
  },
];

export default speaker;
